#template-table {
    width: 100%;
    border-collapse: collapse;
    margin: 2rem auto;
}

#template-table th {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    font-family: 'railroad-gothic-atf', sans-serif;
    color: #232323;
}

#template-table td {
    border: 1px solid #ddd;
    padding: 15px;
    vertical-align: top;
    text-align: center;
    background-color: #fff;
}

.shift-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.gig {
    background: linear-gradient(to right, rgba(27,27,27,1) 0%, rgba(37,37,37,1) 100%);
    border: 1px solid rgba(27,27,27);
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: #FBFCEA;
    position: relative;
}

.delete-gig{
    position: absolute;
    top: -11px;
    right: -11px;
    height: 22px;
    width: 22px;
    cursor: pointer;
}

.add-button {
    background-color: unset;
    color: #f47fdf;
    border: 2px dotted #f47fdf;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.add-button:hover {
    background-color: #f47fdf;
    color: white;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 300px;
    text-align: center;
}

.modal h3 {
    margin-bottom: 20px;
}

.save-btn {
    background-color: #f47fdf;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 5px;
}

.close-btn {
    background-color: #fff;
    border: 1px solid #f47fdf;
    color: #f47fdf;
    border-radius: 5px;
    padding: 8px 12px;
    margin: 5px;
}

.modal-select{
    width: 100%;
    height: 48px;
    border-radius: 8px;
    margin: 5px 0;
}