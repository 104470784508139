#booking-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.booking-info{
    color: #6e767b;
}

.booking-header{
    color: #395076;
}

.booking-date{
    height: 55px;
    box-sizing: border-box;
    border: 1px solid #9e9e9e ;
    border-radius: 4px;
    width: 200px;
    padding: 10px;
    margin: 1rem auto;
}

.date-label{
    font-weight: bold;
    color: #395076;
    margin-right: 10px;
    margin-left: 10px;
}